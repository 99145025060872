<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Categoría</template>
    <template #body>
      <FormText
        label="Nombre"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
      />
      <FormText
        label="Posicion"
        icon="fa-solid fa-arrow-down-1-9"
        v-model="form.pos"
      />
      <FormText
        label="Url"
        icon="fa-solid fa-link"
        v-model="form.url"
      />
      <FormSelect
        v-model="form.padre"
        label="Categoría"
        :options="categorias"
        icon="fa-solid fa-columns"
      />
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default {
  components: {
    FormText,
    FormSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      nombre: null,
      pos: null,
      url: null,
      padre: null,
      selected: null,
    },
    categorias: [],
  }),
  async mounted() {
    await this.$store.dispatch("getListaCategorias")
    await this.$store.dispatch('getArbolCategorias')
    this.categorias = this.$store.getters.listaCategorias.map(c => {
      const cat = {value: c.id, name: c.nombre}      
        if (c.pos === 0) cat.name = `=== ${c.nombre} ===`
        if (c.pos === 1) cat.name = `### ${c.nombre} ###`
        if (c.pos > 1){
          const spaces = '-'.repeat((c.pos-1) * 1)
          cat.name = `${spaces}> ${c.nombre}`
        }
        return cat;
    }).splice(1);
    if (this.item) {
      this.form = {...this.item};
      this.form.padre = this.item.id_padre || 0;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveData() {
      const id = this.form.id
      const form = {
        nombre: this.form.nombre,
        pos: this.form.pos,
        url: this.form.url,
        id_padre: this.form.padre,
      }
      if (this.form.id) {
        this.$store
          .dispatch("updateCategoria", {
            id,
            form,
          })
          .then(() => {
            this.close();
          });
      } else {
        this.$store.dispatch("createCategoria", form).then(() => {
          this.close();
        });
        this.$emit('saved')
      }
    },
  },
};
</script>
<style></style>
